import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";
import { createClient } from "../../../lib/helpers";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

const useStyles = makeStyles((theme) => ({
  tagsWidgetContainer: {
    marginTop: '1em',
    border: '0.5px solid #D4D4D4',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    }
  },
  titleContainer: {
    backgroundColor: '#3C465F',
    padding: '16px',
    boxShadow: 'inset 0px -1px 0px rgba(170, 169, 228, 0.3)'
  },
  tagsContainer: {
    backgroundColor: '#FFFFFF',
    padding: '12px 16px 0px',
    [theme.breakpoints.down("md")]:{
      flexDirection: "column",
    },
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF'
  },
  tag: {
    fontFamily: 'Poppins',
    color: '#5B5AC7',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    border: '0.5px solid #807ED7',
    borderRadius: '48px',
    padding: '8px',
    display: 'block',
    marginBottom: '12px',
    marginRight: '12px',
    textDecoration: 'none'
  },
  imageContainer: {
    width: '40px',
    marginRight: '12px'
  },
  infoContainer: {
    width: 'calc(100% - 52px)'
  },
  adviserImage: {
    border: '1px solid #807ED7',
    borderRadius: '40px'
  },
  adviserName: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700'
  },
  jobTitle: {
    color: '#6464F6',
    fontFamily: 'Poppins',
    fontSize: '13px',
    lineHeight: '`8px',
    fontWeight: '500'
  },
  adviserContainer: {
    paddingBottom: '12px'
  }
}));

const client = createClient();
const pageType = "people";

function ContributingAdvisersWidget(props) {
  const { } = props;
  const [advisers, setAdvisers] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      var topicId = '';

      var pathname = window.location.pathname.replace('/forum/topics/', '');

      var pathnameSplit = pathname.split('/');

      if (pathnameSplit && pathnameSplit.length > 0) {
        topicId = pathnameSplit[0];
      }

      var adviserIds = await getAdviserIdsByTopicId(topicId);

      var advisers = await getAdvisers(adviserIds);

      setAdvisers(advisers);

      console.log('adv: ', advisers);


    })();
  }, []);

  var getAdviserIdsByTopicId = async function (topicId) {

    var result = await fetch(process.env.GATSBY_QUESTIONAPI + '/AdvisersByTopicId/Advisers', {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topicId)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });

    if (result.Success) {
      return result.Result;
    } else {
      return [];
    }
  }

  var getAdvisers = async function (adviserIds) {
    const params = {
      pageType: pageType,
    }

    var queryAdviserIds = '';

    for (var i = 0; i < adviserIds.length; i++) {
      var propertyName = 'adviserId' + i.toString();
      params[propertyName] = adviserIds[i];

      queryAdviserIds = queryAdviserIds + '$adviserId' + i.toString() + ',';
    }

    var query = '*[_type == $pageType && defined(publishedAt) && dashboardId in [' + queryAdviserIds.replace(/,\s*$/, "") + '] &&!(_id in path("drafts.**"))]';

    query += '{ _id, dashboardId, name, jobTitle, numberOfLikes, email, image, averageRating, "slug": slug.current }'


    return await client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  return advisers && advisers.length > 0 ? (
    <div className={classes.tagsWidgetContainer}>
      <div className={classes.titleContainer}>
        <label className={classes.title}>Experts</label>
      </div>
      <Grid container className={classes.tagsContainer}>
        {advisers && advisers.map((adviser) => {
          return (<Grid item className={classes.adviserContainer}>
            <Grid container>
              <Grid item className={classes.imageContainer}>{adviser.image && <img
                src={imageUrlFor(buildImageObj(adviser.image))
                  .width(40)
                  .height(40)
                  .fit("crop")
                  .url()}
                alt=""
                className={classes.adviserImage}
              />}</Grid>
              <Grid item className={classes.infoContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <label className={classes.adviserName}>{adviser.name}</label>
                  </Grid>
                  <Grid item xs={12}>
                    <label className={classes.jobTitle}>
                      {adviser.jobTitle}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>)
        })}
      </Grid>
    </div>
  ) : (<></>);
}

export default ContributingAdvisersWidget;
