import React, { useState, useEffect } from "react";
import ContainerForPages from "../../../components/containerForPages";
import Container from "../../../components/container";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import useSearch from "../../../hooks/useSearch"

import makeSearch from "../../../components/forumComponents/searchComponents/makeSearch";

import SearchCard from "../../../components/forumComponents/searchComponents/searchCard";
import TagsWidget from "../../../components/widgets/forum/tagsWidget";
import TopicAdvertWidget from "../../../components/widgets/forum/topicAdvertWidget";
import TrendingTopicsWidget from "../../../components/widgets/forum/trendingTopicsWidget";
import SearchWidget from "../../../components/widgets/forum/searchWidget";
import ForumPagination from "../../../components/forumComponents/forumPagination"
import ForumBreadcrumb from "../../../components/forumComponents/forumBreadcrumb";
import { getTopicPageBreadcrumbs } from "../../../components/forumComponents/forumBreadcrumb";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "calc(100% - 370px)",
    paddingTop: "29px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    },
  },
  sideBar: {
    width: "300px",
    marginTop: "2.1em",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
      marginTop: 'initial'
    },
  },
  topicContainer:{
    display: "flex",
    flexDirection: "column",
    "& > * + *":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    "& > div:first-of-type":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]:{
        marginBottom: "24px",
      },
    },
    "& > div:last-of-type":{
      marginBottom: 0,
    },
  },
  marginBottomTemp: {
    marginBottom: "20px",
  },
  marginTopTemp: {
    paddingTop: "20px",
  },
  categoryHeading: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontSize: "28px",
    lineHeight: "42px",
    color: "#3C465F",
    margin: 0,
    paddingBottom: "24px",
  },
}))


function Search(props) {

  const { rName } = props;
  const classes = useStyles();

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const [page, setPage] = useState(1);

  var qs = "";

  if (url.indexOf('?') > -1) {
    qs = url.split('?')[1];
  }

  const [SearchData, errors] = useSearch(qs, page);

  //makeSearch(qs, page);//

  var postsFromSearchData = SearchData && SearchData.posts;

  var paginationFromSearchData = SearchData && SearchData.pagination;

  const pagination = paginationFromSearchData;

  const breadCrumbs = getTopicPageBreadcrumbs();

  const searchCards = postsFromSearchData && postsFromSearchData.map((searchPost) => {
    const { topic, user, content, upvotes, replies } = searchPost;

    return (
      <SearchCard title={topic.title} user={user} likes={upvotes}
        timestampISO={topic && topic.timestampISO} slug={topic.slug} postcount={topic.postcount} content={content} pID={topic.mainPid} />
    )
  })

  const handleChange = (event, value) => {
    setPage(value)

    window.scrollTo(0, 0);

  }

  return (
    <ContainerForPages isCoursePage={true}>
      <Helmet>
        <title>
          Search results - Boring Money
        </title>
      </Helmet>
      <Container>
        <Grid container justifyContent={"space-between"} className={classes.marginBottomTemp}>


          <Grid item className={classes.content}>

            <ForumBreadcrumb pageBreadcrumb={breadCrumbs} />
            <div className={classes.buttonHeaderContainer}>
              <h4 className={classes.categoryHeading}>Search Results</h4>
            </div>
            <div className={classes.topicContainer}>
              {searchCards}
            </div>
            <ForumPagination pagination={pagination} handleChange={handleChange} />
          </Grid>

          <Grid item className={classes.sideBar}>

            <div className={classes.marginTopTemp}>
              <SearchWidget />
              <div className={classes.advertContainer}>
                <TopicAdvertWidget />
              </div>
              <TrendingTopicsWidget />
              {/*<TagsWidget />*/}
            </div>

          </Grid>
        </Grid>
      </Container>
    </ContainerForPages>
  )
}
export default Search
