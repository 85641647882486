import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Button, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// @ts-ignore
import Likes from "../icons/Likes.svg";
// @ts-ignore
import Replies from "../icons/Replies.svg";
// @ts-ignore
import RepliesWhite from "../icons/RepliesWhite.svg";
import formatDistance from "date-fns/formatDistance";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $createParagraphNode, $getRoot, $createTextNode, $createLineBreakNode, $getDecoratorNode } from "lexical";
import { $getSelection } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import ToolbarPlugin from "../plugins/ToolbarPlugin"
import { HeadingNode } from "@lexical/rich-text"
import FormHandler from "../topicComponents/createTopicForm";
import serializeToHtml from "../utilities/lexicalDeserializer";
import DeserializationPlugin from "../plugins/DeserializationPlugin";
import TeaserCard from "../topicComponents/teaserCard"
import uuid from "uuid"

import LikesCard from "../topicComponents/likes";
import ReusableButton from "../../landingPageComponents/blocks/reuseableCampusComponents/ReusableButton";


const useStyles = makeStyles((theme) => ({
  cardContainer: {
    minHeight: "250px!important",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    borderRadius: "24px",
    background: "#FFFFFF",
    borderBottomLeftRadius: "50px",
    borderBottomRightRadius: "50px",
    [theme.breakpoints.up("md")]: {
      minHeight: "314px",
      flexWrap: "nowrap",
    },
  },
  textBox: {
    backgroundColor: "#FFFFFF",
    color: "#3C465F",
  },
  tagReplyLikesHolder: {
    padding: "1rem",
    marginTop: "auto",
    boxShadow: "inset 0px 1px 0px #F0F0FF",
    color: "#3C465F",
    [theme.breakpoints.up("md")]: {
      padding: "12px 24px 12px 24px",
      marginTop: "auto",
    },
  },
  replyAuthorCTAHolder: {
    backgroundColor: "#6464F6",
    padding: "1rem",
    borderBottomRightRadius: "24px",
    borderBottomLeftRadius: "24px",
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      padding: "16px 24px 16px 24px",
    },
  },
  titleHolder: {
    padding: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C465F",
    [theme.breakpoints.up("md")]: {
      padding: "24px 24px 8px 24px",
    },
  },
  introParaHolder: {
    padding: "0 1rem 1rem 1rem",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C465F",
    "-webkit-mask-image": "linear-gradient(180deg, #000 40%, transparent)",
    [theme.breakpoints.up("md")]: {
      padding: "0 24px 0px 24px",
    },
  },
  introParaHolderThreadCard: {
    padding: "0 1rem 1rem 1rem",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C465F",
    [theme.breakpoints.up("md")]: {
      padding: "0 24px 0px 24px",
    },
  },
  tagsSpan: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C465F",
  },
  tag: {
    background: "#F7F5E7",
    borderRadius: "41px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#3C465F",
    padding: "4px 6px 4px 6px",
    marginRight: "4px",

    display: "inline-block",
    border: '0.5px solid #3C465F'
  },
  likesRepliesBox: {
    paddingTop: "8px",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      justifyContent: "flex-end",
      flexShrink: 2,
    },
  },
  likesText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#6464FF",
    display: "flex",
    alignItems: "center",
  },
  repliesText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#6464FF",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      marginLeft: "12px",
    },
  },
  icon: {
    marginRight: "8px",
  },
  tagsHolder: {
    paddingBottom: "8px",
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
      width: "100%",
    },
  },
  avatarHolder: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  avatar: {
    height: "32px",
    width: "32px",
    border: "0.888889px solid #807ED7",
    borderRadius: "50%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
  },
  authorText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    color: '#ffffff',
    fontWeight: 600
  },
  authorTextHolder: {
    paddingLeft: "8px",
    alignText: "center",
  },
  button: {
    background: "#FF0F5F",
    borderRadius: "42px",
    padding: "0.5rem 1rem 0.5rem 1rem",
    outline: "none",
    color: "inherit",
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      padding: "16px 24px 16px 24px",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "16px !important",
      lineHeight: "18px !important",
      letterSpacing: "0.04em !important",
    },
    '&:hover': {
      backgroundColor: '#FF3377 !important'
    }
  },
  tagsLikesBox: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  buttonPositioning: {
    display: "flex",
    justifyContent: "flex-end",
  },
  whiteReplyIcon: {
    marginRight: "8px",
  },
  contentCutOff: {
    height: "50px",
  },
  titleLink: {
    textDecoration: "none",
    color: "inherit",
  },
}));

type SearchCardProps = {
  title: string;
  tags: {
    class: string;
    value: string;
    valueEncoded: string;
    valueEscaped: string;
  }[];
  likes: number;
  postcount?: number;
  slug?: string;
  lastReply?: {
    user: {
      banned: boolean;
      banned_until_readable: string;
      displayname: string;
      "icon:bgColor": string;
      "icon:text": string;
      picture: string;
      postcount: number;
      reputation: number;
      signature?: string;
      status: string;
      uid: number;
      username: string;
      userslug: string;
    };
  };
  timestampISO: string;
  isThreadCard?: boolean;
  content?: string;
  topicID?: number;
  user: {
    banned: boolean;
    banned_until_readable: string;
    displayname: string;
    "icon:bgColor": string;
    "icon:text": string;
    picture: string;
    postcount: number;
    reputation: number;
    signature?: string;
    status: string;
    uid: number;
    username: string;
    userslug: string;

  }
  pID: number;
};



function Placeholder() {
  return <div>What's on your mind...</div>;
}
const SearchCard = ({
  title,
  tags,
  likes,
  postcount,
  lastReply,
  timestampISO,
  slug,
  isThreadCard,
  content,
  topicID,
  user,
  pID,
}: SearchCardProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [formOpen, setFormOpen] = useState(false);
  const [replyFormOpen, setReplyFormOpen] = useState(false)
  const renderTags =
    tags &&
    tags.map((tag) => {
      return <a href={'/forum/tags/' + tag.value} target="_blank"><span className={classes.tag}>#{tag.value}</span></a>;
    });


  const [getLikes, setLikes] = useState(likes);

  const userId = window.localStorage.getItem('ForumUserId');

  let replies = postcount - 1;

  const getNumberOfDays = (num) => {
    let today = new Date();
    let todayISO = today.toISOString();

    const DAY_UNIT_IN_MILLISECONDS = 24 * 3600 * 1000;
    const diffInMilliseconds =
      new Date(todayISO).getTime() - new Date(num).getTime();
    const diffInDays = diffInMilliseconds / DAY_UNIT_IN_MILLISECONDS;

    return Math.round(diffInDays);
  };

  const daysAgo = getNumberOfDays(timestampISO);

  const handleClick = (slug) => {
    if (isThreadCard) {
      setFormOpen(true)
      setReplyFormOpen(true)
    } else {
      window.location.href = `/forum/posts/${slug}`;
    }
  };

  const decodeTitleHTML = () => {
    return {__html: title}
  }

  const avatarURL = null;

  const cardDate = new Date(timestampISO);
  const today = new Date();

  const timePosted = timestampISO ? formatDistance(cardDate, today) : null;

  var key = uuid.v4();

  return (
    <Grid key={key} container direction={"column"} className={classes.cardContainer}>
      <Grid item md={12} className={classes.titleHolder}>
        <a className={classes.titleLink} href={`/forum/posts/${slug}`}><span dangerouslySetInnerHTML={decodeTitleHTML()}></span></a>
      </Grid>
      <Grid
        item
        md={12}
        className={
          isThreadCard
            ? classes.introParaHolderThreadCard
            : classes.introParaHolder
        }
      >

          {/*<LexicalComposer*/}
          {/*  initialConfig={{*/}
          {/*    readOnly: true,*/}
          {/*    onError(error) {*/}
          {/*      throw error;*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <DeserializationPlugin content={content} element={"output"} isTeaser={true} />*/}
          {/*<div id={"output"} className={classes.contentCutOff}>*/}
          {/*    <RichTextPlugin*/}
          {/*      contentEditable={<ContentEditable />}*/}
          {/*      placeholder={<Placeholder />}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</LexicalComposer>*/}
        <TeaserCard pID={pID} />

      </Grid>
      <Grid item md={12} className={classes.tagReplyLikesHolder}>
        <div className={classes.tagsLikesBox}>
          {
            tags && tags.length > 0 &&

            <Grid className={classes.tagsHolder}>
              <span className={classes.tagsSpan}>Tags:</span> {tags && renderTags}
            </Grid>
          }
          <Grid container className={classes.likesRepliesBox}>
            {/*<span className={classes.likesText}>*/}
            {/*  <img src={Likes} className={classes.icon} />*/}
            {/*  {likes} {likes !== 1 ? "Likes" : "Like"}*/}
            {/*</span>*/}
            <LikesCard likes={getLikes} pID={pID} userID={userId} slug={slug} />

            {!isThreadCard && (
              <span className={classes.repliesText}>
                <img src={Replies} className={classes.icon} />
                {replies} {replies !== 1 ? "Replies" : "Reply"}
              </span>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid
        container
        item
        md={12}
        className={classes.replyAuthorCTAHolder}
        justifyContent={replies == 0 ? "flex-end" : null}
      >
        {replies >= 0 && (
          <Grid container item xs={9} md={8} alignContent="center">
            <Grid item className={classes.avatarHolder}>
              {/*<img src={avatarURL} className={classes.avatar} />*/}
              <div
                className={classes.avatar}
                style={{ backgroundColor: user["icon:bgColor"] }}
              >
                <span>{user["icon:text"]}</span>
              </div>
            </Grid>
            <Grid item className={classes.authorTextHolder}>
              Posted{" "}
              {daysAgo === 0
                ? "today"
                : daysAgo !== 1
                  ? `${daysAgo} days ago`
                  : `${daysAgo} day ago`}
              <span className={classes.authorText}>
                by @{user.displayname}
              </span>
            </Grid>
          </Grid>
        )}

        {isThreadCard && (
          <Grid container item xs={9} md={8} alignContent="center">
            <Grid item className={classes.avatarHolder}>
              {/*<img src={avatarURL} className={classes.avatar} />*/}
              <div
                className={classes.avatar}
                style={{ backgroundColor: user["icon:bgColor"] }}
              >
                <span>{user["icon:text"]}</span>
              </div>
            </Grid>
            <Grid item className={classes.authorTextHolder}>
              {isThreadCard ?
                <>
                  Posted {timePosted} ago
                </>
                : <>
                  Posted
                  {daysAgo === 0
                    ? "today"
                    : daysAgo !== 1
                      ? `${daysAgo} days ago`
                      : `${daysAgo} day ago`}
                </>
              }
              <span className={classes.authorText}>
                by @{user.displayname}
              </span>
            </Grid>
          </Grid>
        )}

        <Grid item xs={3} md={4} className={classes.buttonPositioning}>
            <ReusableButton linkText={
              matchesDesktop && isThreadCard ? (
                <>
                  <img className={classes.whiteReplyIcon} src={RepliesWhite} />
                  Reply{" "}
                </>
              ) : isThreadCard ? (
                "Reply"
              ) : matchesDesktop ? (
                "View conversation"
              ) : (
                "view"
              )
            } autoHeight={true} variant={matchesDesktop ? "large" : "medium" } buttonType={"circular"} arrowIcon={false} buttonColour={"pink"} customClickEvent={() => handleClick(slug)}
                            newTab={false}>
            </ReusableButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SearchCard;
