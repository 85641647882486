import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
//import ExitIntent from "../utils/exitIntent";
import { getParameterByName } from "../lib/helpers";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }

`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  const data = useStaticQuery(query);


  useEffect(() => {
    (async () => {
      try {
        window.BrazePageView();

      } catch (e) {
        console.log("err-blocked")
      }

      if (typeof window !== 'undefined') {
        var value = getParameterByName('brazeid')

        console.log("bid", value)

        if (value) {

          //braze - change user if passed in from emails
          try {
            window.changeBrazeUserFromQS(value)
          } catch (e) {
            console.log("err-changeBrazeUserFromQS",e)
          }
          
        }
      }

    })();
  }, []);


  //useEffect(() => {

  //  const removeExitIntent = ExitIntent({

  //    threshold: 30,

  //    eventThrottle: 100,

  //    onExitIntent: () => {

  //      (async () => {
  //        try {
  //          window.exitIntent();

  //        } catch (e) {
  //          console.log("err-blocked")
  //        }


  //      })();
  //    },

  //  })

  //  return () => {

  //    removeExitIntent()

  //  }

  //})

  if (!data.site) {
    throw new Error(
      'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
    );
  }
  return (


    <Layout
      {...props}
      showNav={showNav}
      onHideNav={handleHideNav}
      onShowNav={handleShowNav}

      />


  );
}

export default LayoutContainer;
