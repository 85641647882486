import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import useApiResult  from "./useApiResult";
import { getTopics } from "./requests";

const useTopics = (page, rName, sortBy) => {

  if (typeof rName!="undefined") {
    rName = rName.replace("/forum/topics", "category");
  }

  if (rName=="category/") {
    rName = "";
  }

  const request = useMemo(() => getTopics(page, rName, sortBy), [page, sortBy])
  return useApiResult(request);
};

export default useTopics;
