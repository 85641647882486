import React, { useEffect, useState } from "react";

function TopicAdvertWidget(props) {
  const { categoryId } = props;
  const [zoneId, setZoneId] = useState(0);

  useEffect(() => {
    (async () => {
      if (categoryId !== 0) {
        var zoneId = await getZoneId();

        setZoneId(zoneId);
      }     
    })();
  }, [categoryId]);

  var getZoneId = async function () {
    var result = await fetch(
      process.env.GATSBY_QUESTIONAPI + "/AdvertZoneByTopicId/AdvertZone",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(categoryId),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      }).catch((error) => console.log("error", error));

    if (result.Success) {
      return result.Result;
    } else {
      return 0;
    }
  };

  return (
    <>
      { categoryId !== 0 && <iframe
        id="a5f1f9b0"
        name="a5f1f9b0"
        data-src={`https://revive.boringmoney.co.uk/www/delivery/afr.php?zoneid=${zoneId}&cb=12345`}
        frameborder="0"
        scrolling="no"
        width="300"
        height="250"
        allow="autoplay"
        name={`8`}
        data-cookiefirst-category="advertising"
        data-revive-type="true"
      >
        <a
          href="https://revive.boringmoney.co.uk/www/delivery/ck.php?n=aeaf1089&cb=12345"
          target="_blank"
        >
          <img
            src={`https://revive.boringmoney.co.uk/www/delivery/avw.php?zoneid=${zoneId}&amp;cb=12345&n=aeaf1089"`}
            border="0"
            alt=""
          />
        </a>
      </iframe>
      }
    </>
  );
}

export default TopicAdvertWidget;
