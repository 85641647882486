import React, { useEffect, useState } from "react";
import LoginForm from "../../components/account/loginForm";
import { navigate } from "gatsby"
import Posts from "./routes/posts"
import Topics from "./routes/topics"
import TopicsByTagName from "./routes/topicsByTagName"
import Forum from "./routes/forum"
import Layout from "../../containers/layout";
import { getUserObject } from "../../lib/helpers";
import Category from "./routes/category"
import Search from "./routes/search"
import uuid from "uuid";

function ForumRoute(props) {
  var pName = typeof props !== 'undefined' ? props.location.pathname : null;

  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [shouldRedirectToLoginPage, setShouldRedirectToLoginPage] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [notification, setNotification] = useState("");
  const isPrivatePage = true;


  useEffect(() => {
    (async () => {
      await getNotification();
     
    })();
  }, []);


  useEffect(() => {
    if (window.location.pathname === '/visible/login/' || window.location.pathname === '/visible/login') {
      setIsLoginPage(true);
    }

    (async () => {
      window.addEventListener("storage", onStorageUpdate);

      var token = window.localStorage.getItem("token");

      var userObject = await getUserObject(token);

     
      setIsUserAuthenticated(userObject.IsAuthenticated)

      //console.log("userObject.IsAuthenticated", isUserAuthenticated)

      if (!userObject.IsAuthenticated) {
        if (isPrivatePage) {
         // window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
        }
      } else {

        if (!userObject.EmailConfirmed) {
          window.location.href = '/visible/verify-email'
        } else {
          setEmailConfirmed(true);
        }

        setIsUserAuthenticated(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (isPrivatePage && shouldRedirectToLoginPage) {
      window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
    }
  }, [shouldRedirectToLoginPage]);



  var getNotification = async function () {

    const url = typeof window !== 'undefined' ? window.location.href : '';
    var urlArr = url.split("?notification=");
    var postUrl = "";

    if ((urlArr.length > 1) && (urlArr[1] != "")) {

      var note = await getNotificationRoute(urlArr[1]);

      var postIDAnchor = "#" + urlArr[1].match(/(\d+)/)[0];
      
      try {

        note = note.replace('"', '').replace('"', '').replace('/topic', '/forum/posts').replace('?lang=en-GB', '').replace('?lang=en-US', '')

        //console.log("note2-91", note)

        const arr = note.match(/[0-9]+$/);

        //console.log("arr-93", arr)

        var page = "?page=";

        if (arr!=null) {
          page = "?page=" + Math.ceil((arr[0] / 6))
        }

        note = note.replace(/\d+$/, '') //remove trailing ID

        note = note + postIDAnchor + page;

        //console.log("note-107", note)

        window.location.replace(note)

      } catch (e) {
        console.log("err", e)

        window.location.replace("/forum/topics");

        
      }
     

    }

  }

  var getNotificationRoute = async function (url) {
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    url = url.replace("/post", "/api/post")
    url = url.replace("http:", "https:")

    console.log(url)

    if (url.includes("topic")) {
      window.location.replace("/forum/topics");
    }

    return fetch(url, requestOptions)
      .then((response) => response.text())
      .then((responseText) => responseText)
      .catch(error => { console.log('error', error); window.location.replace("/forum/topics");});
  }


  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    if (key === "isUserAuthenticated") {
      var isAuthenticated = newValue === 'true' ? true : false;

      setIsUserAuthenticated(isAuthenticated);

      if (!isAuthenticated) {
       // setShouldRedirectToLoginPage(true);
      }
    }
  };

  var getTagName = function (url) {
    var split = url.split('/tags/');

    if (split.length === 1) {
      return '';
    } else if (split.length > 1) {
      return decodeURIComponent(split[1]);
    }
  }


  if (pName.includes('/tags')) {
    return (
      <Layout>
        {<TopicsByTagName rName={pName} tagName={getTagName(pName)} />}
      </Layout>
    );
  }

  if (pName.includes("post")) {

    //console.log("pName",pName)

    var threadSlug = pName.replace("/forum/posts", '');

    //console.log("threadSlug",threadSlug)
    return (
      <Layout>
        {<Posts key={uuid.v4()} rName={pName} threadSlug={threadSlug} isAuth={isUserAuthenticated} />}
      </Layout>
    );
  }

  if (pName.includes("topic")) {

    if ((pName == "/forum/topics")|| (pName == "/forum/topic")) {
      return (
        <Layout>
          {<Category rName={pName} isAuth={isUserAuthenticated}/>}
        </Layout>
      );
    }
    else {
      return (
        <Layout>
          {<Topics rName={pName} isAuth={isUserAuthenticated} />}
        </Layout>
      );
    }


  }

  if (pName.includes("category")) {
    return (
      <Layout>
        { <Category rName={pName} />}
      </Layout>
    );
  }

  if (pName.includes("search")) {
    return (
      <Layout>
        { <Search rName={pName} />}
      </Layout>
    );
  }

  if ((pName = "/forum/") || ((typeof pName == "null"))) {

    const url = typeof window !== 'undefined' ? window.location.href : '';
    var urlArr = url.split("?notification=");

    if ((urlArr.length > 1) && (urlArr[1] != "")) {
      getNotification();
    }
    else {
      if (typeof window !== 'undefined') {
        window.location.replace("/forum/topics");
      } else {
        return null;
      }
    }
  }

  return null

}
export default ForumRoute

