import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tagsWidgetContainer: {
    marginTop: '1em',
    border: '0.5px solid #D4D4D4',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    }
  },
  titleContainer: {
    backgroundColor: '#3C465F',
    padding: '16px',
    boxShadow: 'inset 0px -1px 0px rgba(170, 169, 228, 0.3)'
  },
  tagsContainer: {
    backgroundColor: '#FFFFFF',
    padding: '12px 16px 12px 16px'
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF'
  },
  tag: {
    fontFamily: 'Poppins',
    color: '#5B5AC7',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    border: '0.5px solid #807ED7',
    borderRadius: '48px',
    padding: '8px',
    display: 'block',
    marginBottom: '8px',
    marginRight: '8px',
    textDecoration: 'none'
  },
  topicAnchor: {
    textDecoration: 'none'
  },
  topicLabel: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    lineHeight: '14px',
    fontSize: '13px',
    fontWeight: 700,
    display: 'block',
    paddingBottom: '12px',
    paddingTop: '12px',
    cursor: 'pointer'
  },
  tag: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    fontWeight: '500',
    backgroundColor: '#F7F5E7',
    fontSize: '12px',
    lineHeight: '16px',
    border: '0.5px solid #3C465F',
    borderRadius: '48px',
    padding: '8px',
    display: 'block',
    marginBottom: '8px',
    marginRight: '8px',
    textDecoration: 'none'
  }
}));

function TrendingTopicsWidget(props) {
  const { } = props;

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    (async () => {
      var topics = await getTrendingTopics();

      setTopics(topics);
    })();
  }, []);

  var getTrendingTopics = async function () {
    var response = await fetch(process.env.GATSBY_QUESTIONAPI + '/AllTrendingTopics', {
      method: 'GET',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });

    return response;
  }

  const classes = useStyles();

  return (
    <div className={classes.tagsWidgetContainer}>
      <div className={classes.titleContainer}>
        <label className={classes.title}>Trending Topics</label>
      </div>
      <Grid container className={classes.tagsContainer}>

        {topics && topics.length > 0 && topics.map((topic) => {
          return <Grid item>
            <a href={topic.Url} className={classes.tag}>{topic.Label}</a>
          </Grid>
        })}
      </Grid>
    </div>
  );
}

export default TrendingTopicsWidget;
