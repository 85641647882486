import * as React from "react";
import { useState, useEffect, useMemo } from "react";

function MakeSearch(props) {

  const { searchTerms, page } = props;

  const [results, setResults] = useState();
  const [errors, setErrors] = useState();

  //const request = useMemo(() => getSearch(searchTerms, page), [page])

  //var myHeaders = new Headers()

  //myHeaders.append("Authorization", "Bearer 5e947acd-45b0-4a2a-8a56-1d801ad2e5b8")
  //myHeaders.append("Accept", "application/json")


  //var requestOptions ={
  //  method: 'GET',
  //  headers: myHeaders,
  //}

  //if (searchTerms != "") {

    var newSearchTerms = searchTerms + "&page=" + page;

    useEffect(() => {
      fetch(`https://bmforum.boringmoney.co.uk/api/search?${newSearchTerms}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json', Authorization: "Bearer 5e947acd-45b0-4a2a-8a56-1d801ad2e5b8"
        }
      })
        .then(async (response) => {
          if (response.ok) {
            setResults(await response.json());
            setErrors(null);
          } else {
            setErrors(await response.text());
          }
        })
        .catch((err) => {
          setErrors(err.message);
        });
    }, [newSearchTerms]);

    return [results, errors]
  //}
  //else {
  //  return null;
  //}

};

export default MakeSearch;
