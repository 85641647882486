import React, { useState, useEffect, useRef } from "react";
import ContainerForPages from "../../../components/containerForPages";
import Container from "../../../components/container";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import useTopics from "../../../hooks/useTopics"
import useTopicsByTagName from "../../../hooks/useTopicsByTagName";
import TopicAdvertWidget from "../../../components/widgets/forum/topicAdvertWidget";
import TrendingTopicsWidget from "../../../components/widgets/forum/trendingTopicsWidget";
import ContributingAdvisersWidget from "../../../components/widgets/forum/contributingAdvisersWidget";
import TagsWidget from "../../../components/widgets/forum/tagsWidget";
import TopicCard from "../../../components/forumComponents/topicComponents/topicCard"
import ForumPagination from "../../../components/forumComponents/forumPagination"
import { useLocation } from "@reach/router";
import queryString from "query-string";
import FormHandler from "../../../components/forumComponents/topicComponents/createTopicForm";
import ForumBreadcrumb from "../../../components/forumComponents/forumBreadcrumb";
import { getTopicPageBreadcrumbs } from "../../../components/forumComponents/forumBreadcrumb";
import { Helmet } from "react-helmet";
import uuid from "uuid";


const useStyles = makeStyles((theme) => ({
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    },
  },
  sideBar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
      marginTop: 'initial'
    },
    marginTop: '5em'
  },
  topicContainer: {
    display: "flex",
    flexDirection: "column",
    "& > * + *":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    "& > div:first-of-type":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]:{
        marginBottom: "24px",
      },
    },
    "& > div:last-of-type":{
      marginBottom: 0,
    },
    "& > div:first-of-type":{
     marginBottom: "16px",
      [theme.breakpoints.up("md")]:{
       marginBottom: "24px",
      },
    },
  },
  marginBottomTemp: {
    marginTop: "29px",
    marginBottom: "20px",
  },
  advertContainer: {
    textAlign: 'center'
  }
}));

function TopicsByTagName(props) {
  const { rName, tagName } = props;
  const classes = useStyles();
  const url = typeof window !== "undefined" ? window.location.href : "";
  const location = useLocation();

  const [page, setPage] = useState(1);
  // const [topicsData, errors] = useTopics(page);
  const [topicsDataByTagName, errorsByTagName] = useTopicsByTagName(page, tagName);

  const topics = topicsDataByTagName && topicsDataByTagName.topics;

  const pagination = topicsDataByTagName && topicsDataByTagName.pagination;

  const tagsTitle = topicsDataByTagName && topicsDataByTagName.tag;

  const breadCrumbs = getTopicPageBreadcrumbs()

  const outputRef = useRef(null)

  const topicCards = topics && topics.map((topic) => {
    const { postcount, slug, tags, user, title, timestamp, timestampISO, votes, teaser, mainPid } = topic;

    console.log(topic, "topic")
    return (
      <TopicCard key={uuid.v4()} title={title} tags={tags} user={user} likes={votes} postcount={postcount} lastReply={teaser}
        timestampISO={teaser && teaser.timestampISO} slug={slug} pID={mainPid} />
    )
  })

  const handleChange = (event, value) => {
    setPage(value)
    window.scrollTo(0, 0);
  }

  const decodedTitle = tagsTitle && decodeURIComponent(tagsTitle);

  return (
    <ContainerForPages isCoursePage={true}>
      <Helmet>
        <title>
          {tagsTitle ? `#${decodedTitle} - Boring Money` : "Boring Money"}
        </title>
      </Helmet>
      <Container>
        <Grid container justifyContent={"space-between"} className={classes.marginBottomTemp}>
          <Grid item className={classes.content}>
            <ForumBreadcrumb pageBreadcrumb={breadCrumbs} />

            <FormHandler tag={topicsDataByTagName && topicsDataByTagName.tag} />
            <div className={classes.topicContainer}>
              {topicCards}
            </div>
            <ForumPagination pagination={pagination} handleChange={handleChange} />
          </Grid>
          <Grid item className={classes.sideBar}>
            <div className={classes.advertContainer}>
              <TopicAdvertWidget />
            </div>
            <TrendingTopicsWidget />
            {/*<TagsWidget />*/}
            <ContributingAdvisersWidget />
          </Grid>
        </Grid>
      </Container>
    </ContainerForPages>
  );
}
export default TopicsByTagName;
